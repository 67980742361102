import React, { useEffect, useRef, useState, useContext } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css'; // Import the video.js CSS
import { ReelsContext } from '../contextReels';

export function ReelsPlayer({ children }) {
  const context = useContext(ReelsContext);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(null);

  const videoUrl = context.state.reel;

  const restartVideo = () => {
    if (playerRef.current) {
      playerRef.current.currentTime(0);
      playerRef.current.play();
    }
  };

  // Initialize video.js player
  useEffect(() => {
    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: false,
        preload: 'auto',
      });

      playerRef.current.on('loadedmetadata', () => {
        const duration = Math.round(playerRef.current.duration() * 1000);
        setVideoDuration(duration); // Duration in milliseconds
        context.handles.setVideoDuration(duration);
      });

      playerRef.current.on('timeupdate', () => {
        const currentTime = Math.round(playerRef.current.currentTime() * 1000);
        setCurrentTime(currentTime); // Current time in milliseconds
        context.handles.setCurrentTime(currentTime);
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [videoUrl]);

  // Restart video if context.currentTime is reset to 0
  useEffect(() => {
    // if (currentTime === 0) {
    //   restartVideo();
    // }
    setCurrentTime(context.currentTime)
  }, [context.currentTime]);

  // Play or pause the video based on the 'playing' prop
  useEffect(() => {
      if (context.state.playing) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }

  }, [context.state.playing]);

  return (
    <>
      <div className="c">
        <div className="x_player_video0">

          <div className="x_player_phone0" />


          {children}

          {Boolean(videoUrl) && (
            <div className="video-js-container" style={{ zIndex: 2, width: '100%' }}
            >
              <video
                ref={videoRef}
                className={`video-js vjs-default-skin x_player_video x_player_video--${context.state.video}`}
                style={{ width: '100%', height: '500px' }}
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}

          {Boolean(context.state.reel) &&
            Boolean(context.state['mp3']) &&
            context.state.video && (
              <div className="x_player_video_sett0">
                <div
                  className={`x_player_video_set_1 ${context.state.video === 'sm' ? 'active' : ''}`}
                  onClick={() => context.handles.setVideo('sm')}
                >
                  <div className="ico">
                    <div>crop_7_5</div>
                  </div>
                </div>

              <div
                className={`x_player_video_set_1 ${context.state.video === 'md' ? 'active' : ''}`}
                onClick={() => context.handles.setVideo('md')}
              >
                <div className="ico">
                  <div>crop_5_4</div>
                </div>
              </div>

              <div
                className={`x_player_video_set_1 ${context.state.video === 'full' ? 'active' : ''}`}
                onClick={() => context.handles.setVideo('full')}
              >
                <div className="ico">
                  <div>fullscreen</div>
                </div>
              </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
}
