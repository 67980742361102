import React, { useEffect, useState } from 'react'
import example_data from './audio/example_data.json';
import mp from './audio/torsunov.mp3';
import video_results from './audio/video_results';
import english_reel from './audio/english_reel.mp4'

const ReelsContext = React.createContext();

const debug = true;
const offline = false;



function ReelsProvider({ children }) {

  const [playing, setPlaying] = useState(false);
  const [video, setVideo] = useState('md')
  const [videoDuration, setVideoDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [zoomLevel, setZoomLevel] = useState(80)

  const [changed, setChanged] = useState([])
  const [playingFragment, setPlayingFragment] = useState()
  const [playingFragmentNext, setPlayingFragmentNext] = useState()

  const [currentState, setCurrentState] = useState([])

  const parseData = (js) => {
    let parsed = js.map((one, ind, arr) => ({
      ...one,
      // id: one.topic.replaceAll(' ', '_') + '_' + one.topic_text.replaceAll(' ', '_'),
      start: one.start_time * 1000,
      end: one.end_time * 1000,
      length: (one.end_time - one.start_time) * 1000,
      chosenVideo: one.chosenVideo ? one.chosenVideo : one.relevant_videos[0].id,
      relevant_videos: [...one.relevant_videos
        .filter(oneF => oneF.video_url)
        .map(oneF => ({
        ...oneF,
          length: oneF.duration * 1000
      }))]
    }))



    parsed = parsed.map((one, ind, arr) => {

      // const prev = arr[ind - 1];
      // const next = arr[ind + 1];

      // if (next && prev) return ({
      //   ...one,
      //   lane:
      //     (prev.start + prev.length < one.start) && (next.start < (one.start + one.length))
      //       ? 2 : 1,
      // })

      // if (next && !prev) return ({
      //   ...one,
      //   lane: (next.start < (one.start + one.length))
      //     ? 2 : 1,
      // })
      // if (!next && prev) return ({
      //   ...one,
      //   lane: prev.start + prev.length < one.start ? 1 : 2,
      // })
      return ({
        ...one,
        lane: ind
      })



    })

    // console.log('parsed = ', parsed);
    return parsed

  }

  const tryParse = (string) => {
    try {
      string = JSON.parse(string)
      return string
    } catch (error) {
      console.log('wrong format');
      return false
    }
  }


  const getDataFromLocal = () => {
    let d = localStorage.getItem('reelsData')
    return d = Boolean(d) ? tryParse(d) : parseData(video_results)
  }

  const [data, setData] = useState(getDataFromLocal())
  // console.log('datadata😄 = ', data);



  const addNewLane = () => {
    const newLane = {
      id: new Date().toTimeString(),
      start: 0,
      start_time: 0,
      end: 10000,
      length: 10000,
      end_time: 10,
      topic: '',
      topic_text: '',
      search_phrase: '',
      video_url: '',
      relevant_videos: []
    }

    // setCurrentState(prev => [newLane, ...prev])
    setData(prev => [newLane, ...prev])
  }

  const removeLane = (id) => {
    setData(prev => [...prev.filter(one => one.id !== id)])
  }

  useEffect(() => {
    console.log('currentState = ', currentState);
  }, [currentState])


  useEffect(() => {

    const fragments = [...data.map(one => ({
      id: one.id,
      start: one.start,
      end: one.start + one.length,
      url: one.relevant_videos.length ? one.relevant_videos.filter(frag => frag.id === one.chosenVideo)[0].video_url : ''
    })).sort((a, b) => {
      return a.start - b.start
    })]

    console.log('fragments = ', fragments);
    setCurrentState(fragments)

  }, [data])



  useEffect(() => {
    let currentFragment = currentState.filter(one => currentTime >= one.start && currentTime < one.end)
    if (currentFragment.length) {

      const frag = data.filter(one => one.id === currentFragment[0].id)[0];
      const getSelected = (frag) => {
        return frag.relevant_videos.filter(one => one.id === frag.chosenVideo)[0]
      }
      const url = getSelected(frag).video_url;
      const currentId = getSelected(frag).id;
      setPlayingFragment(frag.id)
    } else {
      setPlayingFragment()
    }
  }, [currentTime])


  const getVideoUrlFromFragmentId = (id) => {
    const frag = data.filter(one => one.id === id)[0];
    const getSelected = (frag) => {
      return frag.relevant_videos.filter(one => one.id === frag.chosenVideo)[0]
    }
    const url = getSelected(frag).video_url;
    return url
  }



  const addNewChange = (newData =
    { id: '', newStart: 0, newLength: 0, chosenVideo: '' }
  ) => {

    const fragment = data.filter(one => one.id === newData.id)[0]
    const lane = fragment.lane

    let newCurrent = JSON.parse(JSON.stringify(currentState))

    newCurrent = newCurrent.map(one => {
      if (one.id === newData.id) {
        return ({
          ...newData
        })
      } else {
        return ({ ...one })
      }
    })
    console.log('newCurrent = ', newCurrent);
    setCurrentState(newCurrent)

    let prevData = JSON.parse(JSON.stringify(data))
    prevData = prevData.map(one => {
      let newD = newCurrent[newCurrent.findIndex(f => f.id === one.id)]
      return ({
        ...one,
        ...newD
      })
    })
    setData(prevData)
    localStorage.setItem('reelsData', JSON.stringify(prevData))
  };

  const setPlayStart = (id) => {
    // setChangeToSave(id)
    // setChanged((prevChanged) => {
    //   const newArray = [...prevChanged];
    //   const index = newArray.indexOf(id);
    //   if (index > -1) {
    //     newArray.splice(index, 1);
    //   }
    //   newArray.push(id);
    //   return newArray;
    // });
  };


  const handles = {
    getVideoUrlFromFragmentId,
    setChanged,
    setPlayingFragment,
    setPlayingFragmentNext,
    setZoomLevel,
    setPlaying,
    setVideo,
    setVideoDuration,
    setCurrentTime,
    addNewChange,
    setPlayStart,
    addNewLane,
    removeLane
  }

  return (<>
    <ReelsContext.Provider
      value={{
        fragments: data,
        videoDuration,
        video,
        state: {
          currentState,
          reel: english_reel,
          currentTime,
          changed,
          playingFragment,
          playingFragmentNext,
          zoomLevel,
          playing,
          audioDuration: videoDuration,
          durationFull: videoDuration
        },
        handles
      }}
    >
      {children}
    </ReelsContext.Provider >
  </>
  )

}

const ReelsConsumer = ReelsContext.Consumer;

export { ReelsContext, ReelsProvider, ReelsConsumer };
