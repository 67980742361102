import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Tooltip, Popover } from 'antd';
import { tooltipTheme } from './tooltip_theme.js';

export const TooltipH = (props) => {
  const {
    title
  } = { ...props }
  return < Tooltip
    title={title}
    {...tooltipTheme}
    {...props}
  />
}
export const PopoverH = (props) => {
  const {
    title
  } = { ...props }

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };


  // return < Tooltip
  //   title={title}
  //   {...tooltipTheme}
  //   {...props}
  // />
  return < Popover
    title={title}
    open={open}
    onOpenChange={handleOpenChange}
    {...tooltipTheme}
    {...props}
  />
}