import React, { Fragment, useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import { Layout } from './Layout';
import { Debug } from './Debug';
import { DragRange } from './DragRange';
import { Footer } from './Footer';
import { LayoutReels } from './LayoutReels';
import { ReelsContext } from '../contextReels';


export function RoutesMain(props) {



  return (
    <>

      <Router>
        <Routes>



          <Route
            path="/:mediaId"
            element={<>
              <Layout />
              <Debug />
            </>}
          />

          <Route
            path="/reels/"
            element={
              <LayoutReels />
            }
          />


          <Route
            exact path="/"
            element={
              // <Navigate to='/9020231925e711eebf6302420a000003?name=Олег_Геннадьевич_Торсунов_Короткая%20лекция&lang=english' />
              <Navigate to='/cldirx6z7000wvs0ykgx234jl?name=0057_Как_распознать_опасность_&lang=english&folder=2' />
            }
          />

          <Route
            exact path="/subtitles/:mediaId"
            element={
              // <Navigate to='/9020231925e711eebf6302420a000003?name=Олег_Геннадьевич_Торсунов_Короткая%20лекция&lang=english' />
              <Navigate to='/cldirx6z7000wvs0ykgx234jl?name=0057_Как_распознать_опасность_&lang=english&folder=2' />
            }
          />




          <Route
            exact path="/t3"
            element={
              <DragRange />
            }
          />



        </Routes>


      </Router>


    </>
  )
}