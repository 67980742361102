import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { ReelsContext } from '../contextReels';

export function ReelsFragnmentPlayerNew({ playingFragment, children }) {
  const context = useContext(ReelsContext);
  const [currentFragment, setCurrentFragment] = useState(null);
  const fragmentPlayerRef = useRef(null);

  const fragments = context.state.currentState;


  useEffect(() => {
    if (fragmentPlayerRef.current) {
      if (context.state.playing) {
        fragmentPlayerRef.current.play();
      } else {
        fragmentPlayerRef.current.pause();
      }
    }

  }, [context.state.playing]);


  useEffect(() => {
    const { currentTime } = context.state;

    // Find the fragment that matches the current time
    const activeFragment = fragments.find(
      fragment => currentTime >= fragment.start && currentTime <= fragment.end
    );

    if (activeFragment) {
      setCurrentFragment(activeFragment);
    } else {
      setCurrentFragment(null);
    }
  }, [context.state.currentTime]);

  useEffect(() => {
    if (currentFragment && fragmentPlayerRef.current) {
      fragmentPlayerRef.current.play();
    }
  }, [currentFragment]);

  return (
    <div className="c inact" style={{
      position: 'absolute',
      // visibility: context.state.playingFragment === playingFragment ? 'visible' : 'hidden',
      zIndex: 12,
      top: 0,
      right: 0,
      left: 0,
      // bottom: '4em',
      height: '500px',
      // bottom: 0,
      // border: '2px solid red',
      // backgroundColor: '#ff00003f'
    }}>

      {/* Fragment Video Player Overlay */}
      {currentFragment && (
        <video
          ref={fragmentPlayerRef}
          src={currentFragment.url}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 10,
          }}
        />
      )}
    </div>
  );
}