import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Context } from '../context';
import { DraggingFragment } from './DraggingFragment';

import { Scrollbar } from 'react-scrollbars-custom';
import { Time } from './Time';
import { PreloaderGif, PreloaderTracks } from './Preloader';
import { Pop } from './Pop';
import uniqid from "uniqid";


export const Tracks = React.memo(({ children, subtitleMode = false, subtitles }) => {
  const context = useContext(Context);
  const contextRef = useRef(context);
  const trackRef = useRef(null);
  const [leftPadding, setLeftPadding] = useState(0);
  const zoomLevel = 100 - context.state['zoomLevel'];
  const timeoutRef = useRef(null);
  const [tracks, setTracks] = useState(context.state['translationData']);





  const openEditor = (oneTrack) => {
    console.log('openEditor = ');
    context.handles.setPop(oneTrack['id']);
    context.handles.setPlaying();
    context.handles.setPlayingFragment();

    // Clear the timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }

  useEffect(() => {
    contextRef.current = context;
  }, [context]);

  useEffect(() => {
    if (tracks.length) console.log('🔴🔴🔴🔴🔴🔴🔴🔴🔴🔴🔴🔴🔴🔴');
  }, [tracks]);

  const getNextTrack = (currentIndex, tracks) => {
    return currentIndex === tracks.length - 1 ? null : tracks[currentIndex + 1];
  }

  const handlePlayNext = (currentIndex) => {
    // context.handles['setPlayingFragment']();
    setTracks((currentTracks) => {
      if (tracks.length) console.log('Setting tracks from next 🌕🌕🌕🌕🌕🌕');
      const currentTrack = currentTracks[currentIndex];
      const nextTrack = getNextTrack(currentIndex, currentTracks);
      const delay = nextTrack
        ? nextTrack['start'] - (currentTrack['start'] + currentTrack['length'])
        : context.state['durationFull'] - (currentTrack['start'] + currentTrack['length']);

      console.log('delay in ms = ', delay);

      // Only set the current playing fragment here, not the next one yet
      // context.handles['setPlayingFragment'](currentTrack['id']);


      const timeoutId = setTimeout(() => {
        console.log(`Timeout triggered for track ID = ${nextTrack ? nextTrack['id'] : 'none'}`);
        // if (context.state.playing) {
        // Here we're ready to play the next track, so now set the next playing fragment
        if (nextTrack) {
          // Clear the timeout as we're about to play the next track
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;

          contextRef.current.handles['setPlayingFragmentNext']();
          contextRef.current.handles['setPlayingFragment'](nextTrack['id']);
        }
        // } else {
        //   console.log('Skipped playin g next track because playback was stopped.');
        // }
      }, delay);

      timeoutRef.current = timeoutId;
      // console.log(`Timeout set with ID: ${timeoutId}`);
      return currentTracks;
    });
  };

  useEffect(() => {
    if (typeof context.state.scrollTo === 'number') {
      trackRef.current.scrollTo({
        left: context.state.scrollTo,
        behavior: 'smooth'
      });
      context.handles.setScrollTo()
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (typeof context.state.scrollTo === 'number') {
      trackRef.current.scrollTo({
        left: context.state.scrollTo,
        behavior: 'smooth'
      });
      context.handles.setScrollTo()
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [context.state.scrollTo]);




  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      // context.handles.setPlayingFragment()
    }
  }, [context.state.playStart]);




  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [context.state.playingFragment]);

  useEffect(() => {
    if (!context.state.playing && timeoutRef.current) {
      // console.log('Clearing timeout:', timeoutRef.current);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    } else {
      console.log('Not clearing timeout. Playing:', context.state.playing, 'Timeout:', timeoutRef.current);
    }
  }, [context.state.playing]);


  // const clickHandler = (oneTrack, index) => {
  //   // const scrubberWidth = context.state.zoomFragment[1] - context.state.zoomFragment[0];
  //   context.handles.setPlayingFragment(oneTrack['id']);
  //   const newStart = tracks[index]['start'];
  //   console.log('newStart = ', newStart);
  //   context.handles.setPlayStart(newStart);
  //   context.handles.setPlayOriginalStart(tracks[index]['original_start']);
  //   context.handles.setPlaying(true);
  //   context.handles['setZoomFragment']([oneTrack['start'] / zoomLevel, (oneTrack['start'] + context.state.scrubberWidth) / zoomLevel]);
  //   console.log('oneTrack[start] = ', oneTrack['start']);
  //   context.handles.setPlaybackTime(oneTrack['start'])
  //   // console.log(`Playing Fragment ID set to: ${oneTrack['id']}`);
  //   context.handles['setPlayingFragmentNext']();
  //   // if (context.state.playingFragment === oneTrack['id'] && !context.state['playing']) {
  //   // context.handles.setPlaying(true)
  //   // }
  //   // if (context.state.playingFragment !== oneTrack['id'] && !context.state['playing']) {
  //   //   context.handles.setPlaying(false)
  //   // }
  // }


  const currentPlayingFragmentRef = useRef(null);

  useEffect(() => {
    currentPlayingFragmentRef.current = context.state.playingFragment;
  }, [context.state.playingFragment]);

  const currentPlayingFragmentNextRef = useRef(null);

  useEffect(() => {
    currentPlayingFragmentNextRef.current = context.state.playingFragmentNext;
  }, [context.state.playingFragmentNext]);




  useEffect(() => {
    if (trackRef.current) {
      const totalTrackWidth = trackRef.current.scrollWidth;
      const containerWidth = trackRef.current.clientWidth;
      const currentZoomFragment = context.state.zoomFragment[0]; // Assuming this is a time value

      // Calculate the position to scroll to based on the zoomFragment
      const scrollPositionFromZoomFragment = (currentZoomFragment / context.state.durationFull) * totalTrackWidth;

      let newScrollPosition;

      // Decide what should trigger the scroll - the zoomFragment change or the playing fragment
      const targetFragmentId = currentPlayingFragmentNextRef.current || currentPlayingFragmentRef.current;
      if (targetFragmentId) {
        const targetTrack = tracks.find(track => track.id === targetFragmentId);
        if (targetTrack) {
          const targetTrackPosition = (targetTrack.start / context.state.durationFull) * totalTrackWidth;
          newScrollPosition = targetTrackPosition - (containerWidth / 2) + leftPadding + (targetTrack.length / 2 / zoomLevel);
          // newScrollPosition = targetTrackPosition - (containerWidth / 2);
        }
      } else {
        // If no target fragment is set, use the zoomFragment to determine the scroll position
        newScrollPosition = scrollPositionFromZoomFragment - (containerWidth / 2) + leftPadding;
      }

      // trackRef.current.scrollTo({
      //   left: newScrollPosition,
      //   behavior: 'smooth'
      // });
    }
  }, [
    context.state.zoomFragment,
    context.state.durationFull,
    context.state.playingFragment,
    context.state.playingFragmentNext,
    leftPadding,
    tracks,
    zoomLevel
  ]);





  useEffect(() => {
    if (context.state['translationData'].length) {
      setTracks([...context.state['translationData']])
    }
  }, [context.state.translationData, tracks.length]);

  const updatePosition = (id, newPosition, subtitles, dragStart) => {
    context.handles.updatePosition(id, newPosition, tracks, subtitles, dragStart)
  };
  const updateLength = (id, newLength, subtitles) => {
    context.handles.updateLength(id, newLength, tracks, subtitles)
  };

  const updateTrack = (id,
    newData
  ) => {
    let prevTracks = JSON.parse(JSON.stringify(tracks))
    let newTracks = prevTracks.map((track) =>
      track.id === id ? { ...track, ...newData } : track
    );
    setTracks(newTracks)
  };

  // useEffect(() => {
  //   context.handles.setNewData(tracks);
  // }, [tracks])




  return (
    <>

      {context.state.pop &&
        <Pop
        oneTrackId={context.state.pop}
        handleClose={() => context.handles.setPop()}
        updateTrack={updateTrack}
        />
      }

      <div className="x_tracks0">





        {!context.state['mp3'] && <PreloaderTracks>
          <PreloaderGif />
        </PreloaderTracks>}




        <div className={subtitleMode ? "x_track x_track--v" : "x_track"} ref={trackRef}>

          {children}

          {/* <div className="x_track_playbac k inact" style={{
            width: context.state['playbackTime'] ? `${context.state['playbackTime'] / zoomLevel}px` : 'auto'
          }} /> */}


          {context.state.russian && Boolean(tracks.length)
            && <div className={subtitleMode ? 'x_track_all_fragments x_track_all_fragments--v' : 'x_track_all_fragments'}
              style={{
                display: 'flex',
                // overflow: 'hidden',
                width: !subtitleMode ?
                  context.state['durationFull'] ? `${context.state['durationFull'] / zoomLevel}px` : 'auto'
                  : '200px',
                height: '10em'
              }}>



              <div
                className="x_track_hover"
                style={{ height: '1em' }}
              />




              {tracks
                .filter(oneTrack => oneTrack['add_background'])
                .map((oneTrack, ind, array) => {
                // console.log('start = ', oneTrack['start']);
                // console.log('length = ', oneTrack['length']);
                let previousTrack = ind === 0 ? null : array[ind - 1];
                let nextTrack = ind === array.length - 1 ? null : array[ind + 1];
                let marginLeft = previousTrack ? oneTrack['start_rus_milli'] - (previousTrack['start_rus_milli'] + previousTrack['length']) : 0;
                let marginRight = nextTrack ? nextTrack['start_rus_milli'] - (oneTrack['start_rus_milli'] + oneTrack['length']) : oneTrack['length'];
                // if (ind < 5) console.log('`track_${oneTrack[id]}_${ind}}` = ', `track_${oneTrack['id']}_${ind}_${marginLeft}`);
                  return (<DraggingFragment
                    subtitles={true}
                    key={`track_${oneTrack['id']}_${ind}_${marginLeft}`}
                    subtitleMode={subtitleMode}
                    oneTrack={oneTrack}
                    ind={ind}
                    id={oneTrack['id']}
                    marginLeft={marginLeft}
                    marginRight={marginRight}
                    handlePlayNext={handlePlayNext}
                    setLeftPadding={setLeftPadding}
                    // clickHandler={clickHandler}
                    start={oneTrack['start_rus_milli']}
                    trimmed_start={oneTrack['trimmed_start']}
                    length={oneTrack['end_rus_milli'] - oneTrack['start_rus_milli']}
                    // length={oneTrack['length']}
                    end_rus_milli={oneTrack['end_rus_milli']}
                    start_rus_milli={oneTrack['start_rus_milli']}
                    trimmed={oneTrack.trimmed}
                    totalLength={context.state['durationFull'] / zoomLevel}
                    tracks={tracks}
                    onUpdatePosition={updatePosition}
                    onUpdateLength={updateLength}
                    timeoutRef={timeoutRef}
                    openEditor={openEditor}
                    draggingTrack={trackRef}
                  // setNewData={setNewData}
                  // onReady={handleReady}
                  />)
              })}



            </div>}

          {Boolean(tracks.length)
            && <div className={subtitleMode ? 'x_track_all_fragments x_track_all_fragments--v' : 'x_track_all_fragments'}
              style={{
                display: 'flex',
                // overflow: 'hidden',
                width: !subtitleMode ?
                  context.state['durationFull'] ? `${context.state['durationFull'] / zoomLevel}px` : 'auto'
                  : '200px',
                height: !subtitleMode ?
                  context.state.video !== 'full' ? '16em' : '5em'
                  : context.state['durationFull'] ? `${context.state['durationFull'] / zoomLevel}px` : 'auto'
              }}>



              <div className="x_track_hover" />




              {tracks.map((oneTrack, ind, array) => {
                // console.log('start = ', oneTrack['start']);
                // console.log('length = ', oneTrack['length']);
                let previousTrack = ind === 0 ? null : array[ind - 1];
                let nextTrack = ind === array.length - 1 ? null : array[ind + 1];
                let marginLeft = previousTrack ? oneTrack['start'] - (previousTrack['start'] + previousTrack['length']) : 0;
                let marginRight = nextTrack ? nextTrack['start'] - (oneTrack['start'] + oneTrack['length']) : oneTrack['length'];
                // if (ind < 5) console.log('`track_${oneTrack[id]}_${ind}}` = ', `track_${oneTrack['id']}_${ind}_${marginLeft}`);
                return (
                  <DraggingFragment
                    subtitles={subtitles}
                    key={`track_${oneTrack['id']}_${ind}_${marginLeft}`}
                    subtitleMode={subtitleMode}
                    oneTrack={oneTrack}
                    ind={ind}
                    id={oneTrack['id']}
                    marginLeft={marginLeft}
                    marginRight={marginRight}
                    handlePlayNext={handlePlayNext}
                    setLeftPadding={setLeftPadding}
                    // clickHandler={clickHandler}
                    start={oneTrack['start']}
                    trimmed_start={oneTrack['trimmed_start']}
                    length={oneTrack['length'] ? oneTrack['length'] : oneTrack['end_rus_milli'] - oneTrack['start_rus_milli']}
                    trimmed={oneTrack.trimmed}
                    end_rus_milli={oneTrack['end_rus_milli']}
                    start_rus_milli={oneTrack['start_rus_milli']}
                    totalLength={context.state['durationFull'] / zoomLevel}
                    tracks={tracks}
                    onUpdatePosition={updatePosition}
                    onUpdateLength={updateLength}
                    timeoutRef={timeoutRef}
                    openEditor={openEditor}
                    draggingTrack={trackRef}
                  // setNewData={setNewData}
                  // onReady={handleReady}
                  />

                )
              })}



            </div>}





          {!tracks.length && <>
            <div style={{ display: 'flex', rowGap: '1em', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '10vh' }}>
              <div className='ico ico--100'>subtitles_off</div>
              Пока нет перевода</div>
          </>}

        </div>
      </div>
    </>
  );
})

// export const Tracks = React.memo(TracksComponent);
