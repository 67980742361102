import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter, useNavigate, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { Context } from '../context';
import { HorizontalSlider } from './HorizontalSlider';
import { VolumeCross } from './VolumeCross';
import { PreloaderGif } from './Preloader';
import { ReelsContext } from '../contextReels';



const languages = {
  'english': {
    title: 'En',
    titleLong: 'английский'
  }
}





export function ReelsHead(props) {

  const context = useContext(ReelsContext);

  const navigate = useNavigate();
  const location = useLocation();



  return (
    <>

      <div
        className='_f'
        style={{ position: 'absolute', top: '1em', left: '1em', zIndex: 15 }}>
        <ReelsPlay enter={true} context={context} />
      </div>

      <div style={{ position: 'absolute', top: '1em', right: '2em', zIndex: 15 }}>
        <div className="x_flex-h">
          {/* <div className="x_text-08" style={{ color: 'white' }}>
            Увеличение:
          </div> */}
          <HorizontalSlider
            value={context.state['zoomLevel']}
            min={10}
            max={90}
            width={200}
            // onValueChange={(val) =>
            //   Math.floor(context.handles['setActiveSegmentLength'](val + 1) / 10) * 10
            // }
            onValueChange={(val) => context.handles['setZoomLevel'](val)}
          />
        </div>
      </div>

      {/* <div className="x_head0">
        <div className="x_head_border" />
        <div className="x_head_l">
          <div className="x_head_logo">
            <div className="x_player_control">
              <ReelsPlay enter={true} context={context} />
            </div>
            <div className="x_head_logo_text ellipsis">{fixName(context.state['name'])}</div>
            <div className="x_head_logo_lang">{fixLang(context.state['lang'])}</div>
          </div>
          <div className='x_head_center'>
          </div>
        </div>
        <div className="x_head_r">
        </div>
      </div> */}

    </>
  )
}





export function ReelsPlay({ enter = false, defaultAction = true }) {

  const context = useContext(ReelsContext);

  useEffect(() => {
    if (enter) {

      const handleKeyPress = (e) => {
        if (e.key === " " && !context.state.pop) { // Spacebar key
          e.preventDefault()
          context.handles.setPlaying(!context.state.playing);
          // context.handles.setPlayingFragment()
        }
      }
      document.addEventListener('keydown', handleKeyPress);

      // Clean up the event listener when the component is unmounted
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      }
    }
  }, [enter, context]);

  return (
    <>
      <div className="x_player_control_play"
        onClick={() => {
          context.handles.setPlaying(prev => !prev)
        }}
      >
        <div className="ico ico--40">
        {!context['state']['playing'] && <div>play_arrow</div>}
        {context['state']['playing'] && <div>pause</div>}
        </div>
      </div>

      <div className="x_player_control_play"
        onClick={() => {
          context.handles.setCurrentTime(0)
        }}
      >
        <div className="ico ico--40">
          <div>restart_alt</div>
        </div>
      </div>
    </>
  )
}
