import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../context';

export const DragRange = () => {
  const context = useContext(Context);
  const zoomLevel = 100 - context.state['zoomLevel'];

  const totalLength = 18000;

  const [tracks, setTracks] = useState([]);

  const updatePosition = (id, newPosition) => {
    setTracks((prevTracks) =>
      prevTracks.map((track) =>
        track.id === id ? { ...track, start: newPosition } : track
      )
    );
  };

  useEffect(() => {
    // console.log('tracks = ', JSON.stringify(tracks));
  }, [tracks])

  return (
    <div style={{ position: 'relative', width: `${totalLength / zoomLevel}px`, height: '40px', backgroundColor: 'lightgrey' }}>
      {tracks.map((track) => (
        <Track
          key={track.id}
          id={track.id}
          start={track.start}
          length={track.length}
          totalLength={totalLength}
          tracks={tracks}
          onUpdatePosition={updatePosition}
          zoomLevel={zoomLevel}
        />
      ))}
    </div>
  );
};





const Track = ({ id, start, length, totalLength, tracks, onUpdatePosition, zoomLevel }) => {
  const [position, setPosition] = useState(start);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const getMaxSpace = () => {
    const prevTrack = tracks.slice(0, tracks.findIndex(t => t.id === id)).pop();
    const nextTrack = tracks.slice(tracks.findIndex(t => t.id === id) + 1).shift();

    const min = prevTrack ? prevTrack.start + prevTrack.length : 0;
    const max = nextTrack ? nextTrack.start - length : totalLength - length;

    return { min, max };
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startX;
      const newPosition = position + deltaX * zoomLevel; // Adjust deltaX by zoomLevel

      const { min, max } = getMaxSpace();

      if (newPosition >= min && newPosition <= max) {
        setPosition(newPosition);
        setStartX(e.clientX);
      }
    }
  };

  const handleMouseUp = () => {
    console.log('handleMouseUp = ');
    setIsDragging(false);
    onUpdatePosition(id, position);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, position, startX]);

  return (
    <div
      onMouseDown={handleMouseDown}
      style={{
        position: 'absolute',
        left: `${position / zoomLevel}px`, // Divide by zoomLevel for display
        width: `${length / zoomLevel}px`,   // Divide by zoomLevel for display
        height: '40px',
        backgroundColor: 'blue',
        cursor: 'pointer',
      }}
    >
      {id}
    </div>
  );
};
