import { Button } from 'antd';
import React, { Fragment, useEffect, useState, useContext, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { ButtonH } from './UiKit/ButtonH';

export const ReelsVideoOptions = ({
  id,
  topic = '',
  search_phrase = '',
  start = 0,
  end = 0,
  topic_text = '',
  duration = 0,
  relevant_videos = [],
  optionsShown,
  chosenVideo,
  setOptionsShown = () => void (0),
  handleNewOption = () => void (0)
}) => {


  const [selected, setSelected] = useState(chosenVideo);


  const getSelected = () => {
    return relevant_videos.filter(one => one.id === selected)[0]
  }

  useEffect(() => {
    handleNewOption({
      id: id,
      chosenVideo: selected
    })
  }, [selected])

  return (
    <div className="x_track_video0"
      onMouseLeave={() => setOptionsShown()}
    >

      {Boolean(getSelected()) && <div className="x_track_video"
        onClick={() => setOptionsShown(prev => prev ? false : id)}
        style={{ backgroundImage: relevant_videos.length ? `url("${getSelected().thumbnail_url}")` : '' }}
      />}



      {!Boolean(getSelected()) && <div className="x_track_video_upload0" >
        <ButtonH ghost icon='cloud_upload' style={{ color: 'white' }}></ButtonH>
      </div>}


      <div className={`x_track_video_choose0 ${optionsShown === id ? 'active' : 'inact'}`}>
        <div className="x_track_video_choose_ul">






          {relevant_videos
            .filter(one => one.video_url)
            .map((one, ind) => <ReelsOption
              handleClick={() => setSelected(one.id)}
              key={`option_${one.id}`} {...one} />)}


          <div className="x_track_video_options_info">
            <div className="x_track_video_options_line">
              <div className="x_track_video_options_label">
                <div>Топик:</div>
              </div>
              <div className="x_track_video_options_value">
                {topic}
              </div>
            </div>
            <div className="x_track_video_options_line">
              <div className="x_track_video_options_label">
                <div>Поисковая фраза:</div>
              </div>
              <div className="x_track_video_options_value">
                {search_phrase}
              </div>
            </div>
            <div className="x_track_video_options_line">
              <div className="x_track_video_options_label">
                Текст:
              </div>
              <div className="x_track_video_options_value ellipsis">
                {topic_text}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export const ReelsOption = ({
  title = '',
  relevance_score,
  video_url,
  thumbnail_url,
  duration,
  explanation,
  handleClick,
}) => {
  return (<div className="x_track_video_choose_li"
    onClick={handleClick}
  >
    <div className="x_track_video_choose_thumb"
      style={{ backgroundImage: `url("${thumbnail_url}")` }}
    />
    <div className="x_track_video_choose_info">
      <div className="x_track_video_choose_title">
        {title}
      </div>
    </div>
  </div>
  )
}