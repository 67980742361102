import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { TooltipH } from './PopoverH';

export function ButtonH(props) {

  const {
    onClick = () => void (0),
    children,
    icon = '',
    ghost,
    red,
    flat,
    sm,
    inactive,
    disabled,
    tooltip,
    tooltipPlacement,
    className,
    style = {},
    onMouseEnter = () => void (0),
    onMouseOut = () => void (0),
  } = { ...props }

  if (!tooltip) return (
    <>
      <div
        style={{ ...style }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        className={`x_button ${sm ? 'x_button--sm' : ''} ${ghost ? 'x_button--ghost' : ''}  ${red && !disabled ? 'x_button--red' : ''} ${flat ? 'x_button--flat' : ''} ${inactive ? 'x_button--inactive' : ''} ${disabled ? 'x_button--locked' : ''} ${className ? `${className}` : ''}`}
      >
        {Boolean(icon) &&
          <div className="x_button_icon">
            <div className="t_icon">{icon}
            </div>
          </div>}
        {children}
      </div>
    </>
  )
  if (tooltip) return (
    <>
      <TooltipH
        placement={tooltipPlacement}
        title={<>{tooltip}</>}
      >
        <div className={`x_button ${sm ? 'x_button--sm' : ''} ${ghost ? 'x_button--ghost' : ''}  ${red ? 'x_button--red' : ''} ${flat ? 'x_button--flat' : ''} ${inactive ? 'x_button--inactive' : ''} ${disabled ? 'x_button--locked' : ''}`} onClick={onClick}>
          {Boolean(icon) &&
            <div className="x_button_icon">
              <div className="t_icon">{icon}
              </div>
            </div>}
          {children}
        </div>
      </TooltipH >
    </>
  )
}