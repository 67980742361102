import React, { useEffect, useState } from 'react';
import './range.css';


export const HorizontalSlider = ({ value = 0, onValueChange, width = '80px', min = 0, max = 99 }) => {
  const [val, setVal] = useState(Number(value));

  const handleChange = (event) => {
    const newValue = Number(event.target.value);
    setVal(newValue);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  useEffect(() => {
    const numericValue = Number(value);
    if (!Number.isNaN(numericValue) && numericValue !== val) {
      setVal(numericValue);
    }
  }, [value, val]);

  return (
    <input
      type="range"
      min={min}
      max={max}
      value={val}
      onChange={handleChange}
      style={{ width: width }}
    />
  );
};
