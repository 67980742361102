import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DraggingFragment } from './DraggingFragment';
import { ReelsDragging } from './ReelsDragging';
import { ReelsVideoOptions } from './ReelsVideoOptions';
import { Time } from './Time';
import { PopoverH } from './UiKit/PopoverH';
import { Button, Tooltip, Popover } from 'antd';
import { ButtonH } from './UiKit/ButtonH';

export function ReelTracking({ context }) {
  const zoomLevel = 100 - context.state['zoomLevel'];
  const trackRef = useRef(null);
  const [optionsShown, setOptionsShown] = useState(false);
  const [order, setOrder] = useState(context.fragments.map(one => one.id));

  useEffect(() => {
    if (context.fragments.length !== order.length) {
      setOrder(context.fragments.map(one => one.id));
    }
  }, [context.fragments]);

  const blankTrackExist = context.fragments.some(one => !one.chosenVideo);

  return (
    <>
      <div className="x_tracks0 x_tracks0--videos">
        <div className="x_track x_track--video" ref={trackRef}>
          <div className="x_track_playback inact" style={{
            zIndex: 10,
            opacity: 0.2,
            width: context.state.currentTime ? `${context.state.currentTime / zoomLevel + 5}px` : 'auto',
            mixBlendMode: 'multiply',
            left: '4em',
            position: 'absolute',
            zIndex: 1000,
          }} />

          <Time context={context} style={{ zIndex: 100, top: 0, left: '4em' }} />

          <div className="x_reeltracks_conteiner">
            <div className="x_track_all_order0">
              <div className="x_track_new"
                onClick={() => context.handles.addNewLane()}
              >
                <div className={`x_track_new_button ${blankTrackExist ? 'inactive' : ''}`}>
                  <div className="t_icon t_icon--40">add</div>
                </div>
              </div>

              {order.map(oneId => (
                <PopoverH title={<>
                  <ButtonH onClick={() => context.handles.removeLane(oneId)}>Remove lane</ButtonH>
                </>}>
                  <div className="x_track_order" key={`order_${oneId}`}>
                    <div className="t_icon t_icon--60">
                      <div>drag_indicator</div>
                    </div>
                  </div>
                </PopoverH>
              ))}
            </div>

            <div className={'x_track_all_fragments x_track_all_fragments--reels'}
              style={{
                display: 'flex',
                width: context['videoDuration'] ? `${context['videoDuration'] / zoomLevel}px` : 'auto',
                height: 'auto',
              }}>
              {context.fragments.map((oneTrack, ind, arr) => (
                <Fragment key={`track_${oneTrack.id}_${ind}`}>
                  <ReelsDragging
                    order={order.findIndex(one => one === oneTrack.id)}
                    {...oneTrack}
                    optionsShown={optionsShown}
                    context={context}
                    oneTrack={oneTrack}
                    ind={ind}
                    id={oneTrack.id}
                    start={oneTrack['start']}
                    length={oneTrack['length']}
                    tracks={context.fragments}
                    nextTrack={arr[ind + 1] ? arr[ind + 1] : null}
                    prevTrack={arr[ind - 1] ? arr[ind - 1] : null}
                    draggingTrack={trackRef}
                  >
                    <ReelsVideoOptions
                      optionsShown={optionsShown}
                      setOptionsShown={setOptionsShown}
                      handleNewOption={context.handles.addNewChange}
                      {...oneTrack}
                    />
                  </ReelsDragging>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
