import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { Context } from '../context';
import { PlayButton } from './PlayButton';
import { HorizontalSlider } from './HorizontalSlider';


export function VolumeCross(props) {
  const context = useContext(Context);

  return (
    <>
      <div className="x_flex-h">
        <div className="x_text-08">
          Перевод ({context.state['volumeTranslated']})
        </div>
        <HorizontalSlider
          width={200}
          min={0}
          max={100}
          value={context.state['volumeOriginal']}
          onValueChange={(val) => {
            context.handles['setVolumeOriginal'](val)
            context.handles['setVolumeTranslated'](100 - val)
          }}
        />
        <div className="x_text-08">
          Оригинал ({context.state['volumeOriginal']})
        </div>

      </div>

    </>
  )
}