import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter, useNavigate, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { Context } from '../context';
import { PlayButton } from './PlayButton';
import { HorizontalSlider } from './HorizontalSlider';
import { VolumeCross } from './VolumeCross';
import { PreloaderGif } from './Preloader';

const languages = {
  'english': {
    title: 'En',
    titleLong: 'английский'
  }
}





export function Head(props) {

  const context = useContext(Context);

  const navigate = useNavigate();
  const location = useLocation();

  const fixName = (name) => {
    if (name && typeof name === 'string') {
      name = name.replaceAll('_', ' ')
      return name
    } else {
      return 'Лекция без имени'
    }
  }

  const fixLang = (lang) => {
    if (!lang || !languages[lang]) return;
    return lang ? `Рус → ${languages[lang]['title']}` : ''
  }

  const handleVideo = () => {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    if (context.state.video) {
      // Remove the 'video' parameter if it exists
      searchParams.delete('video');
      context.handles.setVideo()
    } else {
      // Add the 'video' parameter
      searchParams.set('video', '1');
      context.handles.setVideo(1)
    }

    // Construct the new path
    const newPath = `${location.pathname}?${searchParams.toString()}`;
    navigate(newPath);
  };


  return (
    <>
      <div className="x_head0">
        <div className="x_head_border" />
        <div className="x_head_l">
          <div className="x_head_logo">
            <div className="x_player_control">
              <PlayButton enter={context.state.pop ? false : true} context={context} />
            </div>
            <div className="x_head_logo_text ellipsis">{fixName(context.state['name'])}</div>

            <div className="x_head_chapters0">
              <FolderList
                link={`/${context.state['mediaId']}?name=${context.state['name']}${context.state['lang'] ? `&lang=${context.state['lang']}` : ''}${context.state['video'] ? '&video=1' : ''}`}
                foldersTotal={context.state.foldersTotal}
                activeFolder={context.state.folder}
              />
            </div>

            <div className="x_head_logo_lang">{fixLang(context.state['lang'])}</div>
          </div>
          <div className='x_head_center'>

          </div>
        </div>


        <div className="x_head_r">

          <div className="x_changes_count">
            <div className="x_flex-h">
              <div>Изменений:</div>
              <strong>{context.state.changed.length}</strong>
            </div>
          </div>

          <SaveButton />


          <div >
            <div className={`x_player_video_set_1 ${context.state.russian ? 'active' : ''}`}
              // onClick={() => context.handles.setVideo(context.state.video ? false : 'sm')}
              onClick={() => {
                context.handles.setRussian(r => !r)
              }}
            >
              Ру
            </div>
          </div>

          <div >
            <div className={`x_player_video_set_1 ${context.state.video ? 'active' : ''}`}
              // onClick={() => context.handles.setVideo(context.state.video ? false : 'sm')}
              onClick={() => {
                handleVideo()
              }}
            >
              <div className="ico">
                videocam
              </div>
            </div>
        </div>


        </div>



      </div>

    </>
  )
}



export function SaveButton(props) {
  const context = useContext(Context);
  return (
    <>
      {!context.state.savingStatus && <div className="x_save x_save--saved"
        style={{ width: '8em' }}
      >
        <div>Сохранено</div>
        <div className="ico ico--20">
          <div>check</div>
        </div>
      </div>}

      {context.state.savingStatus && <div className="x_save "
        style={{ width: '8em' }}
      >
        {/* <div>Сохранение</div> */}
        <div style={{ position: 'relative', marginBottom: '-4px', marginTop: '-4px', marginLeft: '-4px', marginRight: '-4px' }}>
          <PreloaderGif size={44} />
        </div>
      </div>}
    </>
  )
}



export function SaveButton1(props) {
  const context = useContext(Context);
  return (
    <>
      {Boolean(context.state.changed.length) && <div className="x_save"
        onClick={context.handles.saveMoving}
      >
        <div>Сохранить</div>
      </div>}




      {!Boolean(context.state.changed.length) && !context.state.savingStatus && <div className="x_save x_save--saved">
        <div>Сохранено1</div>
        <div className="ico ico--20">
          <div>check</div>
        </div>
      </div>}

      {!Boolean(context.state.changed.length) && context.state.savingStatus && <div className="x_save x_save--saved">
        <div>Сохранеие</div>
        <div className="ico ico--20">
          <PreloaderGif />
        </div>
      </div>}



    </>
  )
}

function FolderList({ foldersTotal, activeFolder, link = '/' }) {
  const folders = [];

  activeFolder = parseInt(activeFolder)

  const renderF = (i, arr) => {
    const className = i == activeFolder ? "x_head_chapter active" : "x_head_chapter";
    if (
      i === activeFolder ||
      (!(i > activeFolder + 2) && !(i < activeFolder - 2)) ||
      (i === foldersTotal || i === foldersTotal - 1) ||
      (i === 1 || i === 1 + 1)
    ) {
      return (
        <Link to={`${link}&folder=${i}`} key={`folder_${i}`} className={className}>
          {i}
        </Link>
      );
    } else {
      // Even the fragment should have a key
      return <Fragment key={`folder_dot_${i}`}>.</Fragment>;
    }
  };

  for (let i = 1; i <= foldersTotal; i++) {

    folders.push(
      renderF(i, folders)
    );
  }

  return <>{folders}</>;
}