import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { Context } from '../context';

export function Subtitles(props) {
  const context = useContext(Context);

  return (
    <>
      <div className="x_player_video_subtitles" style={{ zIndex: 10 }}>
        {context.handles.getElementById(context.state['playingFragment'])['captions']}
      </div>

    </>
  )
}