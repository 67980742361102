import React, {
  Component,
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment
} from 'react';
import styled, { css } from 'styled-components';



// import Tap from "react-interactions";
import './Uikit.css';
import { MIcon } from './Uikit';

export const getBoundingClientRect = (element) => {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y
  };
};

export const CssLoginPop0 = styled.div`
  @media (max-width: 991px) {
  }

  @media (max-width: 767px) {
  }

  @media (max-width: 479px) {
  }
`;

const debug = false;
// const debug = true;

export function Select({
  direction,
  onHover = false,
  size = 'md',
  options = [{ title: '–', value: 'none' }],
  darkenBackground = false,
  active,
  menuHandler,
  handleReset,
  placeholder = 'Select',
  separated,
  extra,
  help,
  dropIcon = true,
  width = 100,
  dropLeft,
  theme = 'sb',
  colors = {
    idle: 'rgb(255 255 255 / 46%)',
    bg: 'white',
    accent: '#157afb',
    text: '#00244d',
    radius: '0.4em'
  }
}) {
  const drop = useRef(null);
  const [pos, setPos] = useState(null);

  const [height, setHeight] = useState(null);

  useEffect(() => {
    const windowHeight = window.innerHeight;
    if (!direction && pos && drop.current) {
      const dropPos = getBoundingClientRect(drop.current);
      setHeight(windowHeight / 2 - 200);
      if (windowHeight < pos.bottom + dropPos.height) {
        setToTop(true);
      } else {
        setToTop(false);
      }
    }
  }, [pos]);

  const [toTop, setToTop] = useState(direction);

  const [shown, setShown] = useState(false);

  return (
    <>
      {shown && darkenBackground && <CssZ theme={theme} width={240} />}
      <div style={{ width: width ? `${width}px` : 'auto', minWidth: 44, width: '100%' }}>

        <CssSelect
          accent={colors['accent']}
          bg={colors['bg']}
          text={colors['text']}
          idle={colors['idle']}
          radius={colors['radius']}


          size={size}
          className="xsb_but_link_input"
          style={{
            width: width ? `${width}px` : 'auto',
            pointerEvents: menuHandler ? 'inherit' : 'none',
          }}
          shown={shown}
          onClick={(e) => {
            if (!shown) {
              setShown(true);
              setPos(getBoundingClientRect(e.target));
            }
          }}
        >

          {!menuHandler && (
            <div className="xsb_but_seturl_placeholder">
              <MIcon size={18}>
                lock
              </MIcon>
            </div>
          )}
          {menuHandler && (<CssDrop
            size={size}
            ref={drop}
            className={`${toTop
              ? 'xsb_select_drop0 xsb_select_drop0--top'
              : 'xsb_select_drop0'
              }`}
            // shown={shown}
            onMouseLeave={() => {
              !debug && setShown(false);
            }}
          >
            <CssSelectPlaceholder
              accent={colors['accent']}
              radius={colors['radius']}
              bg={colors['bg']}
              text={colors['text']}

              size={size}
              toTop={toTop}
              shown={shown}
              onClick={() => {
                if (shown) setShown(false);
              }}
            >
              {placeholder && typeof active === 'undefined' && (
                <div className="xsb_but_seturl_placeholder"
                  style={{ paddingRight: dropIcon ? '16px' : '8px' }}
                >{placeholder}</div>
              )}

              {typeof active !== 'undefined' && (
                <div className="xsb_but_seturl_placeholder">
                  {options.map((item) => {
                    const ret = active === item.value
                      ? typeof item.title === 'string'
                        ? item.title
                        : item.title()
                      : '';
                    return ret || null;
                  })}
                </div>
              )}

              {dropIcon && <MIcon size={14} left>
                <div>expand_more</div>
              </MIcon>}
              &nbsp;
            </CssSelectPlaceholder>

            <CssSelectB
              radius={colors['radius']}
              shown={shown}
              className="xsb_select_drop"
              style={{
                boxShadow: '0 3px 22px 4px rgba(64, 69, 74, 0.31)',
                bottom: toTop ? '38px' : 'auto',
                right: dropLeft ? '0' : 'auto',
                position: 'absolute',
                width: width ? 'auto' : '100%',
                justifySelf: 'stretch',
              }}
            >
              {/* <CssMenuLi
                  className="xsb_select_drop_li"
                  key={`preset_0`}
                  onClick={(e)=>{
                    e.preventDefault();
                    setShown(false);
                  }}
                >
              </CssMenuLi> */}

              {typeof extra !== 'undefined' && (
                <CssMenuLi
                  accent={colors['accent']}
                  bg={colors['bg']}
                  text={colors['text']}

                  className="xsb_select_drop_li"
                  key="preset_custom"
                  onMouseEnter={(e) => {
                    e.preventDefault();
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {extra}
                </CssMenuLi>
              )}

              {options.map((item, ind) => (
                <CssMenuLi
                  separated={item['separated']}
                  active={active === item.value}
                  className="xsb_select_drop_li"
                  key={`preset_${item.value}`}
                  onMouseEnter={(e) => {
                    e.preventDefault();
                    shown && onHover && menuHandler(item.value);
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    menuHandler(item.value);
                    setShown(false);
                  }}
                >
                  {Boolean(item['icon']) && <>{item['icon']}&nbsp;&nbsp;</>}

                  {typeof item.title === 'function'
                    ? item.title()
                    : item.title}
                </CssMenuLi>
              ))}

              {!!handleReset && (
                <CssMenuLi
                  className="xsb_select_drop_li"
                  key="preset_reset"
                  onClick={(e) => {
                    e.preventDefault();
                    handleReset();
                    setShown(false);
                  }}
                >
                  <div className="xx_but_menu_icon xx_but_sett_icon--blue">
                    backspace
                  </div>
                  {' '}
                  Reset
                </CssMenuLi>
              )}

              {help}
            </CssSelectB>
          </CssDrop>
          )}

        </CssSelect>

      </div>
    </>
  );
}

export const CssSelectUl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

export const CssSelectB = styled.div`
  transition: all 150ms ease-in-out;
  transform: translateY(-8px);
  padding: 0;
  opacity: 0;
  border-radius: ${({ radius }) => radius ? radius : '3px'};
  /* z-index: 0; */
  ${({ shown }) => shown
    && css`
       {
        transform: translateY(0px);
        opacity: 1;
        /* z-index: 1; */
      }
    `};

  /* &:not(:hover) div {
    background-color: inherit;
    color: blue;
  } */
`;

const sizes = {
  md: {
    height: '32px'
  },
  sm: {
    height: '36px'
  },
  xs: {
    height: '24px'
  }
}

export const CssDrop = styled.div`
  min-height: ${({ size }) => sizes[size]['height']};
  height: ${({ size }) => sizes[size]['height']};
  padding-top: ${({ size }) => sizes[size]['height']};
`;

export const CssSelectPlaceholder = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0px;
  bottom: auto;
  z-index: 10000;
  min-height: ${({ size }) => sizes[size]['height']};
  padding-top: 0px;
  display: flex;
  align-items: center;
  border-radius: ${({ radius }) => radius ? radius : '3px'};


  ${({ toTop }) => toTop
    && css`
       {
        bottom: 0px;
        top: auto;
      }
    `};

  ${({ shown }) => shown
    && css`
       {
        background-color: ${({ bg }) => bg ? bg : 'transparent'};
        box-shadow: inset 0 0 0 2px ${({ accent }) => accent ? accent : '#157afb'};
        color: ${({ text }) => text ? text : '#00244d'};
      }
    `};

    &:hover {
        background-color: ${({ bg }) => bg ? bg : '#ffffff'};
        box-shadow: inset 0 0 0 2px ${({ accent }) => accent ? accent : '#157afb'};
        color: ${({ text }) => text ? text : '#00244d'};
    }

  /* &:not(:hover) div {
    background-color: inherit;
    color: blue;
  } */

  .xx_set_sect & {
    opacity: 0.8;
  }
  .xx_set_sect:hover & {
    opacity: 1;
  }
`;

export const CssSelect = styled.div`
  background-color: ${({ idle }) => idle ? idle : 'transparent'};

  min-height: ${({ size }) => sizes[size]['height']};
  height: ${({ size }) => sizes[size]['height']};
  overflow: ${({ shown }) => (shown ? 'visible' : 'hidden')};
  z-index: 0;
  border-radius: ${({ radius }) => radius ? radius : '3px'};
  ${({ shown }) => shown
    && css`
       {
        z-index: 1;
      }
    `};
    &:hover {
        background-color: ${({ bg }) => bg ? bg : '#ffffff'};
        box-shadow: inset 0 0 0 2px ${({ accent }) => accent ? accent : '#157afb'};
        color: ${({ text }) => text ? text : '#00244d'};
    }
`;




export const CssMenuLi = styled.div`
  color: ${({ active, accent }) => (active ? `${({ accent }) => accent ? accent : '#157afb'}` : 'inherit')};
  font-weight: ${({ active }) => (active ? 'bolder' : 'normal')};
  display: flex;
  align-items: center;
  border-top: ${({ separated }) => (separated ? `1px solid #ccc` : 'none')};
  text-wrap: nowrap;
  /* background-color: ${({ active }) => (active ? '#157afb' : 'transparent')}; */
`;

const CssZ = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  z-index: -1;
  background: ${({ theme }) => (theme === 'sb' ? 'rgba(0, 0, 0, 0.56)' : 'rgba(197, 208, 219, 0.56)')};
`;


