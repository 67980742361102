import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { Context } from '../context';

export function TimeNumbers({ playbackTime }) {
  const context = useContext(Context);
  const zoomLevel = 100 - context.state['zoomLevel'];
  function msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return `${hours}ч. ${minutes}м. ${seconds}с.`;
  }

  return (
    <>
      <div className="x_player_time0 inact"
        style={{ position: 'fixed', marginBottom: '2.4em' }}
      >

        <div className="x_player_time">
          {/* {msToTime(context.state['playbackTime'])} */}
          {msToTime(playbackTime)}
        </div>
      </div>
    </>
  )
}