import React, { Fragment, useEffect, useState, useContext, useParams } from 'react'
import { Link, withRouter, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components';
import { Head } from './Head';
import { PlayerMain } from './PlayerMain';
import { ReelsContext } from '../contextReels';
import { ReelsPlayer } from './ReelsPlayer';

import english_reel from '../audio/english_reel.mp4'
import { ReelsHead } from './ReelsHead';
import { ReelTracking } from './ReelTracking';
import './add2.css';
import { Footer } from './Footer';
import { ReelsFragmentPlayer } from './ReelsFragmentPlayer';
import { ReelsFragnmentPlayerNew } from './ReelsFragnmentPlayerNew';


export function LayoutComponent() {
  const context = useContext(ReelsContext);

  const location = useLocation();

  const zoomLevel = 100 - context.state['zoomLevel'];

  return (
    <>

      <ReelsHead />


      {/* {context.fragments.map(one => <ReelsFragmentPlayer key={one.id} playingFragment={one.id} />)} */}


      <ReelsPlayer>
        <ReelsFragnmentPlayerNew />
      </ReelsPlayer>

      <ReelTracking context={context} />

      {/* <Footer context={context} view={['zoomLevel']} /> */}

      {/* <DebugReels /> */}

    </>
  )
}

export const LayoutReels = React.memo(LayoutComponent);


const DebugReels = ({ }) => {
  const context = useContext(ReelsContext);
  return (
    <div className="_f _t08">
      <div className="_f">
        Duration (ms):{context.videoDuration}
      </div>
      <div className="_f">
        Current time (ms):{context.state.currentTime}
      </div>
    </div>
  )
}