import React, { useContext, useMemo } from 'react';
import { Context } from '../context';

export function Time({ context, style = {} }) {
  // const context = useContext(Context);
  const { audioDuration } = context.state;
  const zoomLevel = 100 - context.state['zoomLevel'];

  const { tickElements, tickStyle } = useMemo(() => {
    const pixelsPerTick = 50;
    const secondsPerTick = Math.max(1, Math.floor((audioDuration / zoomLevel) / pixelsPerTick / 1000));
    const totalTicks = Math.floor((audioDuration / 1000 / secondsPerTick) * zoomLevel);

    const tickElements = Array.from({ length: totalTicks }, (_, index) => (
      <div key={index} className="x_time_tick" />
    ));

    const tickStyle = {
      // width: `${100 / totalTicks}%`,
    };

    return { tickElements, tickStyle };
  }, [audioDuration, zoomLevel]);

  return (
    <div className="x_time0 inact"
      style={{ width: audioDuration / zoomLevel, ...style }}
    >
      {tickElements.map((tick, index) => (
        <div key={index} className={zoomLevel > 20 ? "x_time_tick toodense" : "x_time_tick"} style={tickStyle} />
      ))}
    </div>
  );
}
